body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes appear {
  from{
    opacity: 0;
    scale: 0.5;
  }
  to{
    opacity: 1;
    scale: 1;
  }
  
}
.animate-appear{
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0 cover 40%;
}

.bg-landing{
  background: url(./assets/images/construction.jpg) ;
  background-size: cover;
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
/* Animation Properties */
animation: scaleAnimation 1.5s infinite alternate;


}
.bg-landing::before{
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.225); /* Adjust the alpha value for desired transparency */
  z-index: 3;
}

.bg-tiles{
  background: url(./assets/images/tiles.jpg) ;
  z-index: 1;
  /* opacity: 0.2; */
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;

  background-repeat: no-repeat;
  background-position: bottom;
}
.bg-tiles::before{
  content: '';
  position: absolute;
  inset: 0;
  /* background-color: rgba(0, 0, 0, 0.4);  */
  /* Adjust the alpha value for desired transparency */
  z-index: 3;
}

.bg-projects{
  background: url(./assets/images/projects-bg.jpg) ;
  z-index: 1;
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;

  background-repeat: no-repeat;
  background-position: bottom;
}
.bg-projects::before{
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the alpha value for desired transparency */
  z-index: 3;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial scale */
  }
  100% {
    transform: scale(1.05); /* Scaled up */
  }
}

.auto-slider {
  border-block: 1px solid;
  overflow: hidden;
  user-select: none;
  /* display: flex; */
}

.auto-slider ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  animation: scroll 10s linear infinite; 
  width: calc(100% * 4); /* Adjusted width for 4 items */
}

.auto-slider li {
  min-width: 25%; /* Each item takes up 25% of the ul's width */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}
.auto-slider li:last-child {
  margin-left: 100rem; /* Space between last and first item */
}
.auto-slider:hover ul {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-25%); /* Move left by 25% of the total width */
  }
}

@media screen and (min-width: 437px) and (max-width: 800px) {
  .auto-slider ul{
  animation: scroll 5s linear infinite; 
    width: 150%;
  }
  .auto-slider li {
    min-width: 50%; /* Each item takes up 25% of the ul's width */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }
  .auto-slider li:last-child {
    margin-left: 500px; /* Space between last and first item */
  }
  @keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%); /* Move left by 25% of the total width */
    }
  }
}


.dot-anim:nth-child(2) {
  animation-delay: 0.2s;
}
.dot-anim:nth-child(3) {
  animation-delay: 0.4s;
}
.dot-anim {
  display: inline-block;
  animation: blink 1s infinite;
}
@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* npx tailwindcss -i ./src/index.css -o ./src/output.css --watch */